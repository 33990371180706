// deletableContentMixin.js
import EventBus from "@/EventBus";
import { events } from "@/enums/clientEvents";

export const deletableContentMixin = {
    data() {
        return {
            showAssurancePopUp: false,
            objectToDelete: null,
            objects: []
        };
    },
    methods: {
        toggleShowAssurancePopUp() {
            this.showAssurancePopUp = !this.showAssurancePopUp;
        },
        setObjectToDelete(object) {
            this.objectToDelete = object;
        },
        deleteObject() {
            EventBus.emit(events.DELETE_OBJECT, this.objectToDelete);
        }
    },
};