<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <section id="app-content">
    <router-view/>
  </section>
</template>
<script>
import {routeTo} from "@/Router";
import {getLevels} from "@/scripts/api/levels";
import {verifyToken} from "@/scripts/api/AuthAPI";
import {getActors} from "@/scripts/api/actors";
import {getPlayerBalancing, getPlayers} from "@/scripts/api/players";
import {mapMutations} from "vuex"
import EventBus from "@/EventBus";
export default {
  name: 'App',
  components: {
  },
  mounted() {
    document.title = "AdventuresOfMuddini - Remote Config"
    let token = localStorage.getItem('authToken')
    if (token === null) {
      routeTo('Login')
    }
    else {
      this.init()
    }
    EventBus.on('loadData', () => {
      this.init()
    })
  },
  methods: {
    ...mapMutations('levels', ["setLevels"]),
    ...mapMutations('players', ["setPlayers", "setPlayerBalancing"]),
    ...mapMutations('actors', ["setActors"]),
    init() {
      let token = localStorage.getItem('authToken')

      verifyToken(token, response => {
        switch (response.status) {
          case 200:
            getLevels(token, data => {
              this.setLevels(data)
            })
            getActors(token, data => {
              this.setActors(data)
            })
            getPlayers(token, data => {
              this.setPlayers(data)
            })
            getPlayerBalancing(token, data => {
              this.setPlayerBalancing(data)
            })
            break
          case 401:
            routeTo('Login')
        }
      })
    }

  }
}
</script>

<style>

@import "@/styles/colors.css";
@import "@/styles/generalStyle.css";


  body {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    width: 100vw;
    height: 100vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #app-content {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: Arial;
    position: fixed;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  router-link {
    cursor: pointer;
  }
</style>
