// deletableContentMixin.js

export const addableContentMixin = {
    data() {
        return {
            showForm: false,
        };
    },
    methods: {
        toggleShowForm() {
            this.showForm = !this.showForm;
        },
    },
};