<template>
  <MainPageComponent>
    <template #heading>
      {{playerName}}
    </template>
    <template #content>
      <form class="edit-form">
        <div class="balancing-values biggest">
          <h2 class="text dark">User data</h2>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Player name</label>
            <input type="text" class="text default" :placeholder="playerName" v-model="playerName">
          </div>
            <div class="edit-form-row-secondary default">
              <label class="text dark">Email</label>
              <input type="text" class="text default" :placeholder="player['email']" v-model="player['email']">
            </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Date joined</label>
            <input type="text" class="text default" :placeholder="player['date_joined']" disabled>
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Last login</label>
            <input type="text" class="text default" :placeholder="player['last_login']" disabled>
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Is active</label>
            <input type="checkbox" class="text default" :checked="player['is_active']" disabled>
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Is staff</label>
            <input type="checkbox" class="text default" :checked="player['is_staff']" v-model="player['is_staff']">
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Is Admin user</label>
            <input type="checkbox" class="text default" :checked="player['is_superuser']" v-model="player['is_superuser']">
          </div>




        </div>
        <div class="balancing-values biggest">
          <h2 class="text dark">Player stats</h2>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Current streak</label>
            <input type="number" class="text default" :placeholder="playerStats['current_streak']" v-model="playerStats['current_streak']">
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">Highest streak</label>
            <input type="number" class="text default" :placeholder="playerStats['highest_streak']" v-model="playerStats['highest_streak']">
          </div>
          <div class="edit-form-row-secondary default">
            <label class="text dark">D Baggers</label>
            <input type="number" class="text default" :placeholder="playerStats['d_baggers']" v-model="playerStats['d_baggers']">
          </div>

          <div class="edit-form-row-secondary default">
            <label class="text dark">Lives</label>
            <input type="number" class="text default" :placeholder="playerStats['lives']" v-model="playerStats['lives']">
          </div>


          <div class="edit-form-row-secondary default">
            <label class="text dark">Pain release count</label>
            <input type="number" class="text default" :placeholder="playerStats['pain_release_count']" v-model="playerStats['pain_release_count']">
          </div>

          <div class="edit-form-row-secondary default">
            <label class="text dark">Pain release time</label>
            <input type="text" class="text default" :placeholder="playerStats['pain_release_time']" v-model="playerStats['pain_release_time']" disabled>
          </div>
               </div>
        <div class="submit-button-container">
          <button class="delete-button" @click.prevent="toggleShowAssurancePopUp(); setObjectToDelete(player)">Delete</button>
          <button class="submit-button" @click.prevent="clickSubmit">Submit</button>
        </div>
      </form>
    </template>
  </MainPageComponent>
  <assurance-component v-if="showAssurancePopUp" @closePopUp="toggleShowAssurancePopUp" @deleteObject="deleteObject"/>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {submitObjectEdit} from "@/utils";
import {deletePlayer, editPlayer} from "@/scripts/api/players";
import EventBus from "@/EventBus";
import {events} from "@/enums/clientEvents";
import {routeTo} from "@/Router";
import {deletableContentMixin} from "@/mixins/deletableContentMixin";
import AssuranceComponent from "@/components/assuranceComponent";
export default {
  name: "playerEditComponent",
  components: {AssuranceComponent, MainPageComponent},
  mixins: [deletableContentMixin],
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      player: {},
      playerName: '',
      playerStats: {}
    }
  },
  computed: {
    ...mapState({
      players: state => state.players.players,
    }),
  },
  mounted() {
    EventBus.on(events.DELETE_OBJECT, (object) => {
      let token = localStorage.getItem('authToken')
      deletePlayer(token, object.id, () => {
        this.removePlayer(object.id)
        routeTo('Players')
      })
    })
    this.init(this.players)
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'players/setPlayers':
          this.init(mutation['payload'])
      }})
  },
  methods: {
    ...mapMutations('players', ["setPlayers", "removePlayer"]),

    init(players){
      try {
        let player = players.filter(player => parseInt(player.id) === parseInt(this.id))
        this.player = player[0]
        this.playerName = this.player['player_name']
        this.playerStats = this.player['player_stats']
        document.title = `${this.playerName} - Remote Config`//"Actors - Remote Config"
      } catch (e) {
        return
      }
    },

    clickSubmit() {
      this.player['player_name'] = this.playerName
      this.player['player_stats'] = this.playerStats
      submitObjectEdit(this.player, editPlayer, response => {
        alert(response)
      })
    }
  }
}
</script>

<style scoped>
  @import "@/styles/editPageStyle.css";
  label {
    min-width: 110px;
  }
</style>