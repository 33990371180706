import APIEndpoints from "@/scripts/api/Services/APIEndpoints";

export function getActors(token, callback) {
    APIEndpoints.getActors(token).then(response => {
        callback(response.data)
    }).catch(err => {
        alert(err)
    })
}

export function editActor(token, data, callback) {
    APIEndpoints.editActor(token, data).then(response => {
        console.log(response.data)
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export function addActor(token, data, callback) {
    APIEndpoints.addActor(token, data).then(response => {
        console.log(response.data)
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export async function deleteActor(token, id,callback) {
    APIEndpoints.deleteActor(token, id).then(() => {
        callback()
    }).catch(err => {
        alert(err)
    })
}
