<template>
  <form-background @clickBackground="$emit('closeForm')">
    <template #default>
      <form-wrapper @submit="submit" @closeFrom="$emit('closeForm')">
        <template #heading>Actor hinzufügen</template>
        <template #content>
          <form-row :label-text="'Actor name'" :input-type="formInputTypes.TEXT" v-model="actorName"/>
          <h3 class="text medium dark">Size</h3>
          <form-row :label-text="'Noob'" :input-type="formInputTypes.NUMBER" v-model="sizeNoob"/>
          <form-row :label-text="'Junior'" :input-type="formInputTypes.NUMBER" v-model="sizeJunior"/>
          <form-row :label-text="'Senior'" :input-type="formInputTypes.NUMBER" v-model="sizeSenior"/>
          <h3 class="text medium dark">Velocity</h3>
          <form-row :label-text="'Noob'" :input-type="formInputTypes.NUMBER" v-model="velocityNoob"/>
          <form-row :label-text="'Junior'" :input-type="formInputTypes.NUMBER" v-model="velocityJunior"/>
          <form-row :label-text="'Senior'" :input-type="formInputTypes.NUMBER" v-model="velocitySenior"/>
          <h3 class="text medium dark">Respawn</h3>
          <form-row :label-text="'Noob'" :input-type="formInputTypes.NUMBER" v-model="respawnNoob"/>
          <form-row :label-text="'Junior'" :input-type="formInputTypes.NUMBER" v-model="respawnJunior"/>
          <form-row :label-text="'Senior'" :input-type="formInputTypes.NUMBER" v-model="respawnSenior"/>
        </template>
      </form-wrapper>
    </template>
  </form-background>
</template>
<script>

import FormBackground from "@/components/mainComponents/forms/formBackground";
import FormWrapper from "@/components/mainComponents/forms/formWrapper";
import FormRow from "@/components/mainComponents/forms/formRow";
import {formInputTypes} from "@/enums/formInputTypes";
import {addActor} from "@/scripts/api/actors";
export default {
  name: "actorsForm",
  components: {FormRow, FormWrapper, FormBackground},
  data() {
    return {
      formInputTypes,
      actorName: '',
      sizeNoob: 0,
      sizeJunior: 0,
      sizeSenior: 0,
      velocityNoob: 0,
      velocityJunior: 0,
      velocitySenior: 0,
      respawnNoob: 0,
      respawnJunior: 0,
      respawnSenior: 0,
    }
  },
  methods: {
    submit() {
      this.$emit('closeForm')
      const data =  {
            "actor_name": this.actorName,
            "balancing_values": [
              {
                  "value": "size",
                  "variations": {
                    "noob": this.sizeNoob,
                    "junior": this.sizeJunior,
                    "senior": this.sizeSenior
                  }
              },
              {
                  "value": "velocity",
                  "variations": {
                    "noob": this.velocityNoob,
                    "junior": this.velocityJunior,
                    "senior": this.velocitySenior
                  }
              },
              {
                  "value": "respawn",
                  "variations": {
                    "noob": this.respawnNoob,
                    "junior": this.respawnJunior,
                    "senior": this.respawnSenior
                }
              }
            ]
          }

      addActor(localStorage.getItem('authToken'), data, () => {
      })
    }
  }
}
</script>

<style scoped>
@import "@/styles/formStyle.css";

</style>