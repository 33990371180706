<template>
  <MainPageComponent>
    <template #heading>
      {{actorName}}
    </template>
    <template #content>
      <form class="edit-form">
        <div class="edit-form-row biggest">
          <label for="level-name-input" class="text dark">Actor name</label>
          <input type="text" id="level-name-input" class="text default" v-model="actorName">
        </div>
        <div class="balancing-values biggest">
          <h2 class="text dark">Balancing values</h2>
          <div class="edit-form-row medium" v-for="balancingValue in balancingValues" :key="balancingValue.id">
            <label for="level-name-input" class="text dark">{{balancingValue.values.value}}</label>
            <div class="edit-form-row-secondary default">
              <label class="text dark">noob</label>
              <input type="number" class="text default" v-model="balancingValue.values['variations']['noob']">
            </div>
            <div class="edit-form-row-secondary default">
              <label class="text dark">junior</label>
              <input type="number" class="text default" v-model="balancingValue.values['variations']['junior']">
            </div>
            <div class="edit-form-row-secondary default">
              <label class="text dark">senior</label>
              <input type="number" class="text default" v-model="balancingValue.values['variations']['senior']">
            </div>
          </div>
        </div>
        <div class="submit-button-container">
          <button class="delete-button" @click.prevent="toggleShowAssurancePopUp(); setObjectToDelete(actor)">Delete</button>
          <button class="submit-button" @click.prevent="clickSubmit">Submit</button>
        </div>
      </form>
    </template>
  </MainPageComponent>
  <assurance-component v-if="showAssurancePopUp" @closePopUp="toggleShowAssurancePopUp" @deleteObject="deleteObject"/>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {submitObjectEdit} from "@/utils";
import {deleteActor, editActor} from "@/scripts/api/actors";
import {deletableContentMixin} from "@/mixins/deletableContentMixin";
import EventBus from "@/EventBus";
import {events} from "@/enums/clientEvents";
import AssuranceComponent from "@/components/assuranceComponent";
import {routeTo} from "@/Router";
export default {
  name: "actorEditComponent",
  components: {AssuranceComponent, MainPageComponent},
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      actor: {},
      actorName: '',
      balancingValues: ''
    }
  },
  mixins: [deletableContentMixin],
  computed: {
    ...mapState({
      players: state => state.players.players,
      actors: state => state.actors.actors,
    }),
  },
  mounted() {
    EventBus.on(events.DELETE_OBJECT, (object) => {
      let token = localStorage.getItem('authToken')
      deleteActor(token, object.id, () => {
        this.removeActor(object.id)
        routeTo('Actors')
      })
    })
    this.init(this.actors)
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'actors/setActors':
          this.init(mutation['payload'])
      }})
  },
  methods: {
    ...mapMutations('actors', ["setActors", "removeActor"]),

    init(levels) {
      try {
        let actorsArray = levels.filter(level => {
          return parseInt(level.id) === parseInt(this.id)
        })
        this.actor = actorsArray[0]
        console.log(this.actor)
        this.actorName = this.actor['actor_name']
        this.balancingValues = this.actor['balancing_values']
        document.title = `${this.actorName} - Remote Config`//"Actors - Remote Config"

      } catch (e) {
        return
      }
    },
    clickSubmit() {
      this.actor['actor_name'] = this.actorName
      this.actor['balancing_values'] = this.balancingValues
      submitObjectEdit(this.actor, editActor, response => {
        alert(response)
      })
    }
  }
}
</script>

<style scoped>
  @import "@/styles/editPageStyle.css";
</style>