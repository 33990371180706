<template>
  <div class="login-background">
    <form id="login-form" class="medium">
      <div class="form-header biggest">
        <h1 class="text light bold">Remote config login</h1>
      </div>
      <div class="form-content">
        <div class="form-content-row medium">
          <label for="player-name" class="text light">Email</label>
          <input type="text" id="player-name" v-model="email" class="form-input">
        </div>
        <div class="form-content-row medium">
          <label for="player-name" class="text light">Passwort</label>
          <input type="password" v-model="password" id="player-name" class="form-input">
        </div>
      </div>
      <div class="form-footer default">
        <button class="submit-button text" @click.prevent="clickLogIn">Einloggen</button>
      </div>
    </form>
  </div>
</template>

<script>
import {logIn} from "@/scripts/api/AuthAPI";
import {routeTo} from "@/Router";
import EventBus from "@/EventBus";

export default {
  name: "loginComponent",
  data() {
    return {
      email: "",//"django_intersoft_admin",
      password: ""//"admin#1234!01"
    }
  },
  mounted() {
  EventBus.on("logoutEvent", () => {
    localStorage.removeItem('authToken')
  })
    },
  methods: {
    clickLogIn() {
      logIn(this.password, this.email, token => {
        localStorage.setItem('authToken', token['token']);
        EventBus.emit('loadData')
        routeTo('Actors')
      })
    }
  }
}
</script>

<style scoped>
@import "@/styles/textStyle.css";

.login-background {
  width: 100vw;
  background: var(--background-dark);
  height: 100vh;
  display: flex;
}

#login-form {
  width: 100%;
  height: 100%;
}

.form-header {
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h1 {
  margin: 0;
  padding: 0;
}

.form-content-row {
  width: calc(100% - 20px);
  padding-right: 10px;
  padding-left: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-footer {
  width: calc(100% - 20px);
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
}

.form-input {
  height: 20px;
  border: None;
}

.submit-button {
  border-radius: 5px;
  height: fit-content;
  border: none;
  background: None;
  color: var(--highlight-orange);
}

@media (min-width: 750px) {
  .login-background {
    align-items: center;
    justify-content: center;
  }

  #login-form {
    width: 350px;
    height: 500px;
    margin: auto;
    border-radius: 10px;
  }
}

</style>