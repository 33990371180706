import api from "@/scripts/api/Services/APIService";
import {getCSRFToken} from "@/utils";

export default {
    logIn(password, email) {
        return api(null, getCSRFToken()).post('/config/admin/login/', {
            "password": password,
            "email": email
        })
    },
    verifyToken(token) {
        return api(token).post('/authentication/token/verify/', {
            "token": token
        })
    },
    logout(token) {
        return api(token).post('/authentication/token/logout/', {
            "token": token
        })
    },
    getLevels(token) {
        return api(token).get('/levels/all/all-data', )
    },
    getActors(token) {
        return api(token).get('/actors/all/all-data', )
    },
    getPlayers(token) {
        return api(token).get('/players/all/all-data', )
    },

    getPlayerBalancing(token) {
        return api(token).get('/players/get-player-balancing', )
    },

    editLevel(token, data) {
        return api(token).put('/config/levels/level/' + data.id + '/update/', data)
    },

    editActor(token, data) {
        return api(token).put('/config/actors/actor/' + data.id + '/update/', data)
    },

    addActor(token, data) {
        return api(token).post('/config/actors/add-actor/', data)
    },

    addLevel(token, data) {
        return api(token).post('/config/levels/add-level/', data)
    },

    addPlayer(token, data) {
        return api(token).post('/config/players/add-player/', data)
    },

    editPlayerBalancing(token, data) {
        return api(token).put('/config/player-balancing/update/', data)
    },

    editPlayer(token, data) {
        return api(token).put('/config/player/' + data.id + '/update/', data)
    },

    deletePlayer(token, id) {
        return api(token).delete('/config/player/' + id + '/delete-player')
    },

    deleteActor(token, id) {
        return api(token).delete('/config/actor/' + id + '/delete-actor')
    },

    deleteLevel(token, id) {
        return api(token).delete('/config/level/' + id + '/delete-level')
    },

}