<template>
  <MainPageComponent :show-add-button="false">
    <template #heading>
      Levels
    </template>
    <template #content>
      <div class="list-entry-container" v-for="(level, index) in levels" :key="level.id">
        <p class="text dark">{{index+1}} {{level['level_name']}}</p>
        <span class="material-symbols-outlined icon small" @click="clickRouteToLevelEdit(level.id)">edit</span>
        <span class="material-symbols-outlined icon small delete" @click="toggleShowAssurancePopUp(); setObjectToDelete(level)">delete</span>
      </div>
      <router-view/>
    </template>
  </MainPageComponent>
  <assurance-component v-if="showAssurancePopUp" @closePopUp="toggleShowAssurancePopUp" @deleteObject="deleteObject"/>
  <actors-form v-if="showForm" @closeForm="toggleShowForm"/>

</template>

<script>
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {mapMutations, mapState} from "vuex";
import {routeToEdit} from "@/Router";
import {deletableContentMixin} from "@/mixins/deletableContentMixin";
import AssuranceComponent from "@/components/assuranceComponent";
import EventBus from "@/EventBus";
import {events} from "@/enums/clientEvents";
import {deleteLevel} from "@/scripts/api/levels";
import {addableContentMixin} from "@/mixins/addableContentMixin";
import ActorsForm from "@/components/mainComponents/forms/levelsForm";
export default {
  name: "levelsComponent",
  components: {ActorsForm, AssuranceComponent, MainPageComponent},
  mixins: [deletableContentMixin, addableContentMixin],
  computed: {
    ...mapState({
      players: state => state.players.players,
      levels: state => state.levels.levels,
      actors: state => state.actors.actors,
    }),
  },
  mounted() {
    document.title = "Levels - Remote Config"
    EventBus.on(events.DELETE_OBJECT, (object) => {
      let token = localStorage.getItem('authToken')
      deleteLevel(token, object.id, () => {
        this.removeLevel(object.id)
      })
    })
  },
  methods: {
    ...mapMutations('levels', ["setLevels", "removeLevel"]),

    clickRouteToLevelEdit(levelID) {
      routeToEdit('LevelEdit' , levelID)
    }
  }
}
</script>

<style scoped>

</style>