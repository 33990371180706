<template>
  <section class="main-page-wrapper">
    <div class="main-page-top-bar page-heading-size">
      <h1 class="text dark bold heading">
        <slot name="heading"></slot>
      </h1>
      <button id="new-object-button" v-if="showAddButton" class="medium" @click="$emit('clickAddNewObject')">Neues Objekt hinzufügen</button>
    </div>
    <slot name="pre-content"></slot>
    <div class="main-page-content medium">
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainPageComponent",
  props: {
    showAddButton: {}
  }
}
</script>

<style scoped>

.main-page-wrapper {
  height: calc(100vh - 75px);
  z-index: -100;
  margin-top: 75px;
  margin-bottom: 0;
  width: 100vw;
  overflow: hidden;
  position: fixed;
}

#new-object-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--highlight-orange);
}

.heading {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-page-top-bar {
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  height: 60px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.main-page-content {
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  height: fit-content;
  max-height: 100%;
  display: flex;
  overflow: visible;
  flex-direction: column;
  gap: 10px;
  position: fixed;
}

@media (min-width: 751px) and (max-width: 4000px) {
  .main-page-wrapper {
    margin-top: 0;
    height: 100vh;
    width: calc(100% - 200px);
    margin-right: 0;
    margin-left: 200px;
  }

  .main-page-content {
    max-height: calc(100vh - 80px);

  }
}


</style>