<template>
  <div class="row-wrapper">
    <label class="text dark medium">
    {{labelText}}
    </label>
    <input :type="inputType" class="form-input text dark medium" @input="this.$emit('update:modelValue', $event.target.value)"/>
  </div>
</template>

<script>
export default {
  name: "formRow",
  props: {
    labelText: {
      type: String
    },
    inputType: {
      type: String
    }
  },
  data() {
    return {
      value: null
    }
  }
}
</script>

<style scoped>

  .row-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .form-input {
    flex: 1;
  }

</style>