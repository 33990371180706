export default {
    namespaced: true,
    state: {
        players: [],
        playerBalancing: {}
    },
    getters: {
        getPlayers(state) {
            return state.players
        },
    },
    mutations: {
        setPlayers(state, payload) {
            state.players = payload
        },
        setPlayerBalancing(state, payload) {
            state.playerBalancing = payload
        },
        removePlayer(state, payload) {
            state.players = state.players.filter(entry => {
                return entry.id !== payload
            })
        },
    }
}