<template>
  <MainPageComponent>
    <template #heading>
      Muddini
    </template>
    <template #content>
      <form class="edit-form">
        <div class="balancing-values biggest">
          <h2 class="text dark">Balancing values</h2>
          <div class="edit-form-row medium">
            <div class="edit-form-row-secondary default">
              <label class="text dark">Velocity</label>
              <input type="number" class="text default" v-model="balancingValues['velocity']">
            </div>
            <div class="edit-form-row-secondary default">
              <label class="text dark">Size</label>
              <input type="number" class="text default" v-model="balancingValues['size']">
            </div>
          </div>

        </div>
        <div class="submit-button-container">
          <button class="submit-button" @click.prevent="clickSubmit">Submit</button>
        </div>
      </form>
    </template>
  </MainPageComponent>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {submitObjectEdit} from "@/utils";
import {editPlayerBalancing} from "@/scripts/api/players";
export default {
  name: "playerConfigEditComponent",
  components: {MainPageComponent},
  props: {
    id: {
      required: true
    }
  },
  data() {
    return {
      balancingValues: ''
    }
  },
  computed: {
    ...mapState({
      playerBalancing: state => state.players.playerBalancing,
      levels: state => state.levels.levels,
      actors: state => state.actors.actors,
    }),
  },
  mounted() {
    document.title = "Player balancing - Remote Config"

    this.init(this.playerBalancing)
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'players/setPlayerBalancing':
          this.init(mutation['payload'])
      }})

  },
  methods: {
    ...mapMutations('levels', ["setLevels"]),
    ...mapMutations('players', ["setPlayers"]),
    ...mapMutations('actors', ["setActors"]),

    init(playerBalancing) {
      try {
        this.balancingValues = playerBalancing
        console.log(this.balancingValues)
      } catch (e) {
        return
      }
    },
    clickSubmit() {

      submitObjectEdit(this.balancingValues, editPlayerBalancing, response => {
        alert(response)
        console.log(response.message)
      })
    }
  }
}
</script>

<style scoped>
  @import "@/styles/editPageStyle.css";
  label {
     min-width: 65px;
  }

</style>