import { createStore } from 'vuex';
import levels from "@/stores/modules/levels";
import players from "@/stores/modules/players";
import actors from "@/stores/modules/actors";

const store = createStore({
    modules: {
        levels,
        players,
        actors
    }
})

export default store

