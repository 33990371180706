<template>
  <div id="assurance-container" class="close" @click="clickBackground">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "formBackground",
  methods: {
    clickBackground(event) {
      const validClasses = ["close"]
      const targetClasses = event.target.classList
      if (validClasses.some(className => targetClasses.contains(className))) {
        this.$emit('clickBackground', event)
      }
    }
  }
}
</script>

<style scoped>

#assurance-container {
  z-index: 10000000;
  min-width: 300px;
  min-height: 300px;
  height: calc(100vh - 75px);
  top: 75px;
  margin-top: 75px;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 20%);
}

@media (min-width: 751px) {
  #assurance-container {
    width: calc(100vw - 200px);
    margin-left: 200px;
    right: 0;
    height: 100vh;
    top: 0;
    bottom: 0;
    margin-top: 0;
  }
}
</style>