import APIEndpoints from "@/scripts/api/Services/APIEndpoints";
import {routeTo} from "@/Router";
import EventBus from "@/EventBus";

export function logIn(password, email, callBack) {
    APIEndpoints.logIn(password, email).then(response => {
        if (response.status === 200) {
            callBack(response.data)
        }
    }).catch(err => {
        alert(err)
    })
}

export async function verifyToken(token, callback) {
    APIEndpoints.verifyToken(token).then(response => {
            callback(response)
    }).catch(err => {
        console.log(err)
        routeTo('Login')
        return err
    })
}

export function logout() {
    APIEndpoints.logout(localStorage.getItem('authToken')).then(response => {
        if (response.status === 204) {
            routeTo('Login')
            EventBus.emit('logoutEvent')
        }
    }).catch(err => {
        alert(err)
    })
}

/*export function checkLoginPermission(email, callBack) {
    APIEndpoints.checkLoginPermission(email).then(response => {
        if (response.status == 200) {
            callBack()
        }
    }).catch(err => {
        alert("You don't have the permission to log in!")
    })
}

export function signOut(token, callback) {
    APIEndpoints.signOut(token).then(response => {
        let status = response.status
        switch (status) {
            case 204: {
                callback()
                break
            }
            default: {
                routeTo('Authentication')
                break
            }
        }
    }).catch(err => {
        routeTo('Authentication')
    })
}*/