<template>
    <menu-component/>
    <router-view/>
</template>

<script>
import MenuComponent from "@/menu/MenuComponent";
export default {
  name: "applicationComponent",
  components: {MenuComponent},
}
</script>

<style scoped>

</style>