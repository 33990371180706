<template>
  <div id="menu-top-bar">
    <span class="material-symbols-outlined menu-icon fit-content" @click="toggleMenu">menu</span>
  </div>
  <div id="menu-wrapper" :class="{open: isMenuOpen}">
    <div id="main-items" class="medium">
      <ul id="nav-list">
        <li class="row">
          <div class="row-header">
          <router-link :to="{ name: 'Players' }" class="text light">Players</router-link>
            <!--<span class="material-symbols-outlined icon light fit-content">expand_more</span>-->
        </div></li>
        <li class="row"  id="row-4">
          <div class="row-header">
          <router-link :to="{ name: 'playerConfigEdit' }" class="text light">Muddini</router-link>
        </div></li>
        <li class="row">
          <div class="row-header">
            <router-link :to="{ name: 'Actors' }" class="text light">Actors</router-link>
            <!--<span class="material-symbols-outlined icon light fit-content">expand_more</span>-->
          </div>
        </li>
        <li class="row">
          <div class="row-header">
            <router-link :to="{ name: 'Levels' }" class="text light">Levels</router-link>
            <!--<span class="material-symbols-outlined icon light fit-content">expand_more</span>-->
          </div></li>
      </ul>
    </div>
    <div id="secondary-items" class="medium">
      <ul id="nav-list">
        <li class="row">
          <div class="row-header" @click="clickLogout">
            <span class="text light">Logout</span>
            <span class="material-symbols-outlined icon light fit-content">logout</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {logout} from "@/scripts/api/AuthAPI";
import {mapState} from "vuex";
export default {
  name: "MenuComponent",
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapState({
      players: state => state.players.players,
      levels: state => state.levels.levels,
      actors: state => state.actors.actors,
    }),
  },
   methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
     toggleRow(className) {
    let elements = document.getElementsByClassName(className)

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.toggle('open')
      }
     },
     clickLogout() {
      logout()
     }
   }
}
</script>

<style scoped>
@import "@/styles/iconStyles.css";
@import "@/styles/textStyle.css";
@import "@/styles/generalStyle.css";


  #menu-top-bar {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 75px;
    background: var(--background-dark);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu-icon {
    color: var(--highlight-orange);
  }

  #menu-wrapper {
    background: var(--background-dark);
    position: fixed;
    transition: left 0.25s ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #menu-wrapper.open {
    left: 0;
    transition: left 0.25s ease-out;
  }

  #nav-list {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0;
    gap: 20px;
    flex-direction: column;
  }

  a {
  color: var(--text-color-light);
  text-align: start;
  text-decoration: none;
}

  a:hover, .sub-menu-item:hover, a.router-link-active {
    color: var(--highlight-orange);
  }

  .row-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    cursor: pointer;
  }

  .row-content {
    max-height: 0;
    overflow: hidden;
    display: flex;
    gap: 15px;
    flex-direction: column;
  }

  .row-content.open {
    margin-top: 15px;
    max-height: fit-content;
    overflow: visible;
  }

  .row-header.secondary {
    justify-content: flex-start;
    gap: 10px;
  }

  .row-header:hover .icon, .row-header:hover .text {
    color: var(--highlight-orange);
  }

  .row {
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  #nav-list {
  width: 100%;
  height: 100%;
}

  .sub-menu-item {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-x: hidden;
    height: fit-content;
    cursor: pointer;
  }

  @media(min-width: 250px) and (max-width: 750px) {
    #menu-wrapper {
      width: 220px;
      min-height: calc(100vh - 75px);
      max-height: calc(100vh - 75px);
      left: -220px;
      top: 75px;
    }

    #main-items {
      height: fit-content;
      width: calc(100% - 40px);
      margin-right: 20px;
      margin-left: 20px;
      margin-top: 10px;
    }

    #secondary-items {
      width: calc(100% - 40px);
      margin-right: 20px;
      margin-left: 20px;
      height: fit-content;
    }
  }

  @media (min-width: 751px){
    #menu-top-bar {
      display: none;
    }
    #menu-wrapper {
      height: 100vh;
      left: 0;
      transition: None;
    }
    .open {
      transition: None;
    }
    #main-items {
      height: fit-content;
      width: 90%;
      margin-top: 20px;
      display: flex;
    }
    #secondary-items {
      height: fit-content;
      width: 90%;
      display: flex;
      margin-bottom: 10px;
    }
    #nav-list {
      width: 100%;
      height: 100%;
    }
}

  @media (min-width: 751px) and (max-width: 4000px) {
    #menu-wrapper {
      width: 200px;
    }
  }
</style>