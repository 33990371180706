<template>
  <MainPageComponent :show-add-button="true" @clickAddNewObject="toggleShowForm">
    <template #heading>
      Players
    </template>
    <search-bar-component/>
    <template #content>
      <div class="list-entry-container" v-for="(player, index) in players" :key="player.id">
        <p class="text dark">{{index+1}} {{player['player_name']}}</p>
        <span class="material-symbols-outlined icon small" @click="clickRouteToEdit(player.id)">edit</span>
        <span class="material-symbols-outlined icon small delete" @click="toggleShowAssurancePopUp(); setObjectToDelete(player)">delete</span>
      </div>
    </template>
  </MainPageComponent>
  <assurance-component v-if="showAssurancePopUp" @closePopUp="toggleShowAssurancePopUp" @deleteObject="deleteObject"/>
  <player-form v-if="showForm" @closeForm="toggleShowForm"/>
</template>

<script>
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {mapMutations, mapState} from "vuex";
import {routeToEdit} from "@/Router";
import AssuranceComponent from "@/components/assuranceComponent";
import {deletableContentMixin} from "@/mixins/deletableContentMixin";
import EventBus from "@/EventBus";
import {events} from "@/enums/clientEvents";
import {deletePlayer} from "@/scripts/api/players";
import PlayerForm from "@/components/mainComponents/forms/playerForm";
import {addableContentMixin} from "@/mixins/addableContentMixin";
import SearchBarComponent from "@/components/searchBarComponent";
export default {
  name: "levelsComponent",
  components: {SearchBarComponent, PlayerForm, AssuranceComponent, MainPageComponent},
  mixins: [deletableContentMixin, addableContentMixin],
  computed: {
    ...mapState({
      players: state => state.players.players,
      levels: state => state.levels.levels,
      actors: state => state.actors.actors,
    }),
  },
  mounted() {
    document.title = "Players - Remote Config"
    EventBus.on(events.DELETE_OBJECT, (object) => {
      let token = localStorage.getItem('authToken')
      deletePlayer(token, object.id, () => {
        this.removePlayer(object.id)
      })
    })
  },
  methods: {
    ...mapMutations('players', ["removePlayer"]),
    clickRouteToEdit(id) {
      routeToEdit('playerEdit', id)
    }
  }
}
</script>

<style scoped>

</style>