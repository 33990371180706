<template>
  <MainPageComponent :show-add-button="false">
    <template #heading>
      Actors
    </template>
    <template #content>
      <div class="list-entry-container" v-for="(actor, index) in actors" :key="actor.id">
        <p class="text dark">{{index+1}} {{actor['actor_name']}}</p>
        <span class="material-symbols-outlined icon default" @click="clickRouteToActorEdit(actor.id)">edit</span>
        <span class="material-symbols-outlined icon default delete" @click="toggleShowAssurancePopUp(); setObjectToDelete(actor)">delete</span>
      </div>
    </template>
  </MainPageComponent>
  <assurance-component v-if="showAssurancePopUp" @closePopUp="toggleShowAssurancePopUp" @deleteObject="deleteObject"/>
  <actors-form v-if="showForm" @closeForm="toggleShowForm"/>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {routeToEdit} from "@/Router";
import MainPageComponent from "@/components/mainComponents/MainPageComponent";
import {deletableContentMixin} from "@/mixins/deletableContentMixin";
import EventBus from "@/EventBus";
import {events} from "@/enums/clientEvents";
import AssuranceComponent from "@/components/assuranceComponent";
import {deleteActor} from "@/scripts/api/actors";
import ActorsForm from "@/components/mainComponents/forms/actorsForm";
import {addableContentMixin} from "@/mixins/addableContentMixin";
export default {
  name: "actorsComponent",
  components: {ActorsForm, AssuranceComponent, MainPageComponent},
  mixins: [deletableContentMixin, addableContentMixin],
  computed: {
    ...mapState({
      players: state => state.players.players,
      levels: state => state.levels.levels,
      actors: state => state.actors.actors,
    }),
  },
  data() {
    return {
      showAssurancePopUp: false,
    }
  },
  mounted() {
  document.title = "Actors - Remote Config"
    EventBus.on(events.DELETE_OBJECT, (object) => {
      let token = localStorage.getItem('authToken')
      deleteActor(token, object.id, () => {
        this.removeActor(object.id)
      })
    })
    },
  methods: {
    ...mapMutations('actors', ['removeActor']),
    clickRouteToActorEdit(actorID) {
      routeToEdit('actorEdit' , actorID)
    },

  }
}
</script>

<style scoped>
@import "@/styles/generalStyle.css";
@import "@/styles/iconStyles.css";


</style>