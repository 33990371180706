import APIEndpoints from "@/scripts/api/Services/APIEndpoints";



export function getPlayers(token, callback) {
    APIEndpoints.getPlayers(token).then(response => {
        callback(response.data)
    }).catch(err => {
        alert(err)
    })
}

export function getPlayerBalancing(token, callback) {
    APIEndpoints.getPlayerBalancing(token).then(response => {
        callback(response.data)
    }).catch(err => {
        alert(err)
    })
}

export function editPlayer(token, data,callback) {
    APIEndpoints.editPlayer(token, data).then(response => {
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export function editPlayerBalancing(token, data,callback) {
    APIEndpoints.editPlayerBalancing(token, data).then(response => {
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export function addPlayer(token, data,callback) {
    APIEndpoints.addPlayer(token, data).then(response => {
        callback(response.data)
    }).catch(err => {
        console.log(err)
        alert(err.response.data.message)
    })
}

export async function deletePlayer(token, id,callback) {
    APIEndpoints.deletePlayer(token, id).then(() => {
        callback()
    }).catch(err => {
        alert(err)
    })
}


/*export function checkLoginPermission(email, callBack) {
    APIEndpoints.checkLoginPermission(email).then(response => {
        if (response.status == 200) {
            callBack()
        }
    }).catch(err => {
        alert("You don't have the permission to log in!")
    })
}

export function signOut(token, callback) {
    APIEndpoints.signOut(token).then(response => {
        let status = response.status
        switch (status) {
            case 204: {
                callback()
                break
            }
            default: {
                routeTo('Authentication')
                break
            }
        }
    }).catch(err => {
        routeTo('Authentication')
    })
}*/