<template>
  <form class="form-wrapper shadow">
    <div class="form-header">
      <h1 class="text medium dark">
        <slot name="heading"></slot>
      </h1>
      <span class="material-symbols-outlined" @click="$emit('closeFrom')">close</span>
    </div>
    <div class="form-content">
      <slot name="content"/>
    </div>
    <div class="buttons-wrapper">
      <button @click.prevent="$emit('submit')">Abschicken</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "formWrapper"
}
</script>

<style scoped>
@import "@/styles/generalStyle.css";
@import "@/styles/textStyle.css";

  .form-wrapper {
    width: 350px;
    min-height: 400px;
    height: fit-content;
    background: #ffffff;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .form-header {
    display: flex;
    justify-content: space-between;
  }

  .form-content {
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
  }
</style>