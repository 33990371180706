import {createRouter, createWebHistory} from "vue-router";
import LoginComponent from "@/auth/loginComponent";
import ApplicationComponent from "@/applicationComponent";
import actorsComponent from "@/components/mainComponents/actors/actorsComponent";
import levelsComponent from "@/components/mainComponents/levels/levelsComponent";
import playersComponent from "@/components/mainComponents/players/playersComponent";
import levelEditComponent from "@/components/mainComponents/levels/levelEditComponent";
import actorEditComponent from "@/components/mainComponents/actors/actorEditComponent";
import playerConfigEditComponent from "@/components/mainComponents/playerBalancingConfig/playerConfigEditComponent";
import playerEditComponent from "@/components/mainComponents/players/playerEditComponent";

const routes = [
    {
        name: "Login",
        path: '/',
        alias: '/login',
        component: LoginComponent,
    },
    {
        name: "App",
        path: '/app',
        component: ApplicationComponent,
        children: [
            {
                name: 'Actors',
                path: '/actors',
                alias: '/',
                component: actorsComponent,
                // Weitere Konfigurationen für diese Unterseite
            },
            {
                name: 'Levels',
                path: '/levels',
                component: levelsComponent,
                // Weitere Konfigurationen für diese Unterseite
            },
            {
                name: 'Players',
                path: '/players',
                component: playersComponent,
                // Weitere Konfigurationen für diese Unterseite
            },
            {
                name: 'LevelEdit',
                path: '/level/:id',
                props: true,
                component: levelEditComponent
            },
            {
                name: 'actorEdit',
                path: '/actor/:id',
                props: true,
                component: actorEditComponent
            },
            {
                name: 'playerEdit',
                path: '/player/:id',
                props: true,
                component: playerEditComponent
            },
            {
                name: 'playerConfigEdit',
                path: '/player-config',
                component: playerConfigEditComponent
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: '/admin/',
    routes
})

export default router

export function getRoute(routeName) {
    for (const route of routes) {
        if (route.name == routeName) {
            return route
        } else if (route.children) {
            for (const childRoute of route.children) {
                if (childRoute.name == routeName) {
                    return childRoute
                } else if(childRoute.children) {
                    for (const childRoute2 of childRoute.children) {
                        if (childRoute2.name == routeName) {
                            return childRoute2
                        }
                    }
                }
            }
        }
    }
    throw `No such route: ${routeName}`
}


export function routeTo(destination) {
    const destinationRoute = getRoute(destination)
    if (destinationRoute) {
        console.log("Routing to " + destinationRoute.name)
        router.push(destinationRoute).catch(err => console.log(err))
    }
}

export function routeToEdit(routeName, id) {
    router.push({ name: routeName, params: { 'id': id } })
        .catch(err => console.error(err));
}