import axios from "axios";
import {baseApiUrl} from "@/utils";

//export default (token, url = `http://${baseApiUrl}`) => {
export default (token=null, csrfToken=null, url = `${baseApiUrl}/`) => {

    let tokenString = undefined
    if (token) tokenString = `Token ${token}`
    return axios.create({
        baseURL: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': tokenString,
            'X-CSRFToken': csrfToken,
        }
    })
}