export let baseApiUrl = process.env.VUE_APP_BASE_API_URL

export function getCSRFToken() {
    const csrfCookie = document.cookie.match(/(^|;) ?csrftoken=([^;]*)(;|$)/);
    return csrfCookie ? csrfCookie[2] : null;
}

export function submitObjectEdit(updatedData, apiFunction, callback) {
    apiFunction(localStorage.getItem('authToken'), updatedData, response => {
        callback(response)
    })
}