<template>
  <div class="searchbar-wrapper">k</div>
</template>

<script>
export default {

  name: "searchBarComponent"

}
</script>

<style scoped>

.searchbar-wrapper {
  width: 100px;
  min-height: 40px;
  background-color: red;
}

</style>