<template>
  <form-background @clickBackground="$emit('closeForm')">
    <template #default>
      <form-wrapper @submit="submit" @closeFrom="$emit('closeForm')">
        <template #heading>Player hinzufügen</template>
        <template #content>

          <form-row :label-text="'Player name'" :input-type="formInputTypes.TEXT" v-model="playerName"/>
          <form-row :label-text="'Email'" :input-type="formInputTypes.TEXT" v-model="email"/>
          <form-row :label-text="'Password'" :input-type="formInputTypes.PASSWORD" v-model="password"/>
          <form-row :label-text="'Is staff'" :input-type="formInputTypes.CHECKBOX" v-model="isStaff"/>
          <form-row :label-text="'Is admin'" :input-type="formInputTypes.CHECKBOX" v-model="isAdmin"/>

          <h3 class="text medium dark">Player stats</h3>

          <form-row :label-text="'Current streak'" :input-type="formInputTypes.NUMBER" v-model="currentStreak"/>
          <form-row :label-text="'Highest streak'" :input-type="formInputTypes.NUMBER" v-model="highestStreak"/>
          <form-row :label-text="'D Baggers'" :input-type="formInputTypes.NUMBER" v-model="dBaggers"/>
          <form-row :label-text="'Lives'" :input-type="formInputTypes.NUMBER" v-model="lives"/>

        </template>
      </form-wrapper>
    </template>
  </form-background>
</template>

<script>
import FormBackground from "@/components/mainComponents/forms/formBackground";
import FormWrapper from "@/components/mainComponents/forms/formWrapper";
import FormRow from "@/components/mainComponents/forms/formRow";
import {formInputTypes} from "@/enums/formInputTypes";
import {addPlayer} from "@/scripts/api/players";
import {mapState} from "vuex";
export default {
  name: "playerForm",
  components: {FormRow, FormWrapper, FormBackground},
  computed: {
    ...mapState({
      players: state => state.players.players,
    }),
  },
  data() {
    return {
      formInputTypes,
      playerName: '',
      email: '',
      password: '',
      isStaff: false,
      isAdmin: false,
      currentStreak: 0,
      highestStreak: 0,
      dBaggers: 0,
      lives: 0
     }
  },
  methods: {
    submit() {
      this.$emit('closeForm')
      const data =  {
            "player_name": this.playerName,
            "email": this.email,
            "password": this.password,
            "is_staff": this.isStaff === 'on',
            "is_admin": this.isAdmin === 'on',
            "current_streak": this.currentStreak,
            "highest_streak": this.highestStreak,
            "d_baggers": this.dBaggers,
            "lives": this.lives
          }

      addPlayer(localStorage.getItem('authToken'), data, (player) => {
        this.players.push(player)
      })
    }
  }
}
</script>

<style scoped>
@import "@/styles/formStyle.css";

</style>