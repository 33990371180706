<template>
  <form-background @clickBackground="closePopUp">
    <template #default>
      <div id="assurance-wrapper" class="shadow">
        <div id="assurance-header" class="small">
          <h3 class="text dark">Soll dieses Objekt gelöscht werden?</h3>
          <span class="material-symbols-outlined icon small delete close">close</span>
        </div>
        <div id="buttons-container">
          <button @click="closePopUp" class="close">Nein</button>
          <button @click="submit">Ja</button>
        </div>
      </div>
    </template>
  </form-background>
</template>

<script>
import FormBackground from "@/components/mainComponents/forms/formBackground";
export default {
  name: "assuranceComponent",
  components: {FormBackground},
  props: {},
  methods: {
    closePopUp(event) {
      const validClasses = ["close"]
      const targetClasses = event.target.classList
      if (validClasses.some(className => targetClasses.contains(className))) {
        this.$emit('closePopUp')
      }
    },
    submit() {
      this.$emit('closePopUp')
      this.$emit('deleteObject')
    }
  }
}
</script>

<style scoped>


#assurance-wrapper {
  width: 300px;
  height: 100px;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#assurance-header {
  padding-inline: 10px;
  padding-block: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}



/*
#assurance-container {
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  height: fit-content;
  max-height: 100%;
  display: flex;
  overflow: visible;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 751px) and (max-width: 4000px) {
  #assurance-container {
    margin-top: 0;
    height: 100vh;
    width: calc(100% - 200px);
    margin-right: 0;
    margin-left: 200px;
  }

  .main-page-content {
    max-height: calc(100vh - 80px);

  }
}*/
</style>