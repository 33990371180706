export default {
    namespaced: true,
    state: {
        actors: [],
    },
    getters: {
        getActors(state) {
            return state.actors
        },
    },
    mutations: {
        setActors(state, payload) {
            state.actors = payload
        },
        removeActor(state, payload) {
            state.actors = state.actors.filter(entry => {
                return entry.id !== payload
            })

        },
    }
}