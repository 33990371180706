<template>
  <form-background @clickBackground="$emit('closeForm')">
    <template #default>
      <form-wrapper @submit="submit" @closeFrom="$emit('closeForm')">
        <template #heading>Level hinzufügen</template>
        <template #content>
          <form-row :label-text="'Level name'" :input-type="formInputTypes.TEXT" v-model="actorName"/>
          <h3 class="text medium dark">Time</h3>
          <form-row :label-text="'Noob'" :input-type="formInputTypes.NUMBER" v-model="timeNoob"/>
          <form-row :label-text="'Junior'" :input-type="formInputTypes.NUMBER" v-model="timeJunior"/>
          <form-row :label-text="'Senior'" :input-type="formInputTypes.NUMBER" v-model="timeSenior"/>
          <h3 class="text medium dark">Reward score</h3>
          <form-row :label-text="'Noob'" :input-type="formInputTypes.NUMBER" v-model="rewardNoob"/>
          <form-row :label-text="'Junior'" :input-type="formInputTypes.NUMBER" v-model="rewardJunior"/>
          <form-row :label-text="'Senior'" :input-type="formInputTypes.NUMBER" v-model="rewardSenior"/>
        </template>
      </form-wrapper>
    </template>
  </form-background>
</template>
<script>

import FormBackground from "@/components/mainComponents/forms/formBackground";
import FormWrapper from "@/components/mainComponents/forms/formWrapper";
import FormRow from "@/components/mainComponents/forms/formRow";
import {formInputTypes} from "@/enums/formInputTypes";
import {addLevel} from "@/scripts/api/levels"

export default {
  name: "levelsForm",
  components: {FormRow, FormWrapper, FormBackground},
  data() {
    return {
      formInputTypes,
      actorName: '',
      timeNoob: 0,
      timeJunior: 0,
      timeSenior: 0,
      rewardNoob: 0,
      rewardJunior: 0,
      rewardSenior: 0,

    }
  },

  methods: {
    submit() {
      this.$emit('closeForm')
      const data =  {
            "level_name": this.actorName,
            "balancing_values": [
              {
                  "value": "time",
                  "variations": {
                    "noob": this.timeNoob,
                    "junior": this.timeJunior,
                    "senior": this.timeSenior
                  }
              },
              {
                  "value": "reward_score",
                  "variations": {
                    "noob": this.rewardNoob,
                    "junior": this.rewardJunior,
                    "senior": this.rewardSenior
                  }
              },
            ]
          }

      addLevel(localStorage.getItem('authToken'), data, () => {
      })
    }
  }
}
</script>

<style scoped>
@import "@/styles/formStyle.css";
</style>