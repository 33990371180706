export default {
    namespaced: true,
    state: {
        levels: [],
    },
    getters: {
        getLevels(state) {
            return state.levels
        },
    },
    mutations: {
        setLevels(state, payload) {
            state.levels = payload
        },
        removeLevel(state, payload) {
            state.levels = state.levels.filter(entry => {
                return entry.id !== payload
            })
        },
    }
}