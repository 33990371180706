import APIEndpoints from "@/scripts/api/Services/APIEndpoints";



export function getLevels(token, callback) {
    APIEndpoints.getLevels(token).then(response => {
        callback(response.data)
    }).catch(err => {
        alert(err)
    })
}

export function editLevel(token, data,callback) {
    APIEndpoints.editLevel(token, data).then(response => {
        console.log(response.data)
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export function addLevel(token, data,callback) {
    APIEndpoints.addLevel(token, data).then(response => {
        callback(response.data.message)
    }).catch(err => {
        alert(err)
    })
}

export async function deleteLevel(token, id,callback) {
    APIEndpoints.deleteLevel(token, id).then(() => {
        callback()
    }).catch(err => {
        alert(err)
    })
}

/*export function checkLoginPermission(email, callBack) {
    APIEndpoints.checkLoginPermission(email).then(response => {
        if (response.status == 200) {
            callBack()
        }
    }).catch(err => {
        alert("You don't have the permission to log in!")
    })
}

export function signOut(token, callback) {
    APIEndpoints.signOut(token).then(response => {
        let status = response.status
        switch (status) {
            case 204: {
                callback()
                break
            }
            default: {
                routeTo('Authentication')
                break
            }
        }
    }).catch(err => {
        routeTo('Authentication')
    })
}*/